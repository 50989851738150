import { request } from 'umi';

export type ChangeType = {
    oldPassword?: string;
    newPassword?: string;
    phone?: string;
    authCode?: string;
    password?: string;
};

// 心跳保活请求
export const keepAlive = async () => {
    return request<API.Response>('/api/expire?random=' + Math.random(), {
        method: 'GET',
    });
};

// 退出
export const loginOut = async () => {
    return request<API.Response>('/api/owner/logout', {
        method: 'GET',
    });
};

// 获取用户信息
export const getOwnerInfo = async () => {
    return request<API.Response>('/api/owner/getOwnerInfo', {
        method: 'POST',
    });
};

// 修改用户信息
export const updateInfo = async (data: any) => {
    return request<API.Response>('/api/owner/register/updateInfo', {
        method: 'POST',
        data: { userInfo: data },
    });
};

// 安全设置-修改密码
export const changePassword = async (data: ChangeType) => {
    return request<API.Response>('/api/owner/register/changePassword', {
        method: 'POST',
        data,
    });
};

// 忘记密码-修改登录密码
export const changeLoginPassword = async (data: ChangeType) => {
    return request<API.Response>('/api/owner/register/changeLoginPassword', {
        method: 'POST',
        data,
    });
};

// 修改手机号
export const updatePhone = async (data: ChangeType) => {
    return request<API.Response>('/api/owner/register/updatePhone', {
        method: 'POST',
        data,
    });
};

// 获取token
export const getToken = async () => {
    return request<API.Response>('/api/chat/sysMsg/token', {
        method: 'POST',
    });
};

// 省市区组件获取城市信息
export const getCities = async () => {
    return request<API.Response>('/json/cities.json', {
        method: 'GET',
    });
};

// 获取用户余额
export async function getUserBalance() {
    return request<API.Response>('/api/userBalance/getUserBalance', {
        method: 'POST',
    });
}
