import { getOwnerInfo } from '@/services/user';
import { message, Modal, notification } from 'antd';
import type { RequestConfig } from 'umi';
import { history } from 'umi';

// 运行时配置
// @see https://umijs.org/docs/api/runtime-config

// https://umijs.org/zh-CN/plugins/plugin-initial-state
export async function getInitialState(): Promise<{
    isLogined?: boolean;
    access?: any;
    userInfo?: any;
    balance?: number;
}> {
    // 以下路由，不执行
    if (!['/user/register', '/user/complement', '/result'].includes(history.location.pathname)) {
        const { data } = await getOwnerInfo();

        if (data.type === 0) {
            return {
                access: {},
                isLogined: !!data.userOwner,
                userInfo: data.userOwner,
                balance: 0,
            };
        }
    }
    return {
        access: {},
        isLogined: false,
        balance: 0,
    };
}

const codeMessage: any = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    405: '请求方法不被允许。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};

// 错误处理方案： 错误类型
enum ErrorShowType {
    SILENT = 0,
    WARN_MESSAGE = 1,
    ERROR_MESSAGE = 2,
    NOTIFICATION = 3,
    MODAL = 4,
    REDIRECT = 9,
}

// 与后端约定的响应数据格式
type ResponseStructure = {
    code: number;
    data: any;
    info: string;
    showType?: ErrorShowType;
};

const errorThrower = (res: any) => {
    const { code, data, info, showType } = res;
    if (code !== 0) {
        const error: any = new Error(info);
        error.name = 'BizError';
        error.info = { code, info, data, showType };
        throw error; // 抛出自定义的错误
    }
};

const errorHandler = (error: any, opts: any) => {
    if (opts?.skipErrorHandler) throw error;
    // 自定义 errorThrower 抛出的错误
    if (error.name === 'BizError') {
        const errorInfo: ResponseStructure | undefined = error.info;
        if (errorInfo) {
            const { info: errorMessage, code: errorCode } = errorInfo;
            switch (errorInfo.showType) {
                case ErrorShowType.SILENT:
                    // do nothing
                    break;
                case ErrorShowType.WARN_MESSAGE:
                    message.warn(errorMessage);
                    break;
                case ErrorShowType.ERROR_MESSAGE:
                    message.error(errorMessage);
                    break;
                case ErrorShowType.NOTIFICATION:
                    notification.open({
                        description: errorMessage,
                        message: errorCode,
                    });
                    break;
                case ErrorShowType.MODAL:
                    Modal.error({
                        title: '系统提示',
                        content: errorMessage,
                        mask: false,
                    });
                    break;
                case ErrorShowType.REDIRECT:
                    // TODO: redirect
                    break;
                default:
                    message.error(errorMessage);
            }
        }
    } else if (error.response) {
        // Axios 的错误
        // 请求成功发出且服务器也响应了状态码，但状态代码超出了 2xx 的范围
        const { config, data, status, statusText } = error.response;
        const errorText = data.info ? data.info : codeMessage[status] || statusText;
        notification.error({
            message: `Response status: ${status}`,
            description: `请求地址：${config.url}，错误信息：${errorText}`,
        });
        // 401，登录状态失效
        if (status === 401) {
            location.href = `/user/login?&redirect=${history.location.pathname}`;
        }
    } else if (error.request) {
        // 请求已经成功发起，但没有收到响应
        // \`error.request\` 在浏览器中是 XMLHttpRequest 的实例，
        // 而在node.js中是 http.ClientRequest 的实例
        message.error('None response! Please retry.');
    } else {
        // 发送请求时出了点问题
        message.error('Request error, please retry.');
    }
};

// 请求拦截
// const requestInterceptor: any = (url: string, options: any) => {
//     return { url, options };
// };

// 响应拦截
const responseInterceptors: any = (response: any) => {
    const { data } = response;
    const { code, info } = data;
    // code是200，后端返回业务操作成功提示
    if (code === 200 && info) {
        message.success(info);
    }
    // code不是200，抛出自定义的错误
    if (code !== 200 && info) {
        const error: any = new Error(info);
        error.name = 'BizError';
        // 目前没有与后端定义showType
        error.info = { ...data, showType: 4 };
        throw error;
    }

    return response;
};

// 全局请求配置
// @see https://umijs.org/docs/max/request
export const request: RequestConfig = {
    timeout: 10 * 60 * 1000,
    errorConfig: {
        // 错误抛出
        errorThrower,
        // 错误接收及处理
        errorHandler,
    },
    // 请求拦截器
    // requestInterceptors: [requestInterceptor],
    // 响应拦截器
    responseInterceptors: [responseInterceptors],
};
