import { Spin } from 'antd';
import type React from 'react';

const Loading: React.FC = () => (
    <>
        <Spin size="large" />
    </>
);

export default Loading;
