// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import * as Plugin_0 from '/usr/local/node/project/thtp-frontend/src/app.ts';
import * as Plugin_1 from '/usr/local/node/project/thtp-frontend/src/.umi-production/plugin-access/runtime.tsx';
import * as Plugin_2 from '/usr/local/node/project/thtp-frontend/src/.umi-production/plugin-dva/runtime.tsx';
import * as Plugin_3 from '/usr/local/node/project/thtp-frontend/src/.umi-production/plugin-initialState/runtime.tsx';
import * as Plugin_4 from '/usr/local/node/project/thtp-frontend/src/.umi-production/plugin-model/runtime.tsx';
import * as Plugin_5 from '/usr/local/node/project/thtp-frontend/src/.umi-production/plugin-locale/runtime.tsx';
import { PluginManager } from 'umi';

export function getPlugins() {
  return [
    {
      apply: Plugin_0,
      path: process.env.NODE_ENV === 'production' ? void 0 : '/usr/local/node/project/thtp-frontend/src/app.ts',
    },
    {
      apply: Plugin_1,
      path: process.env.NODE_ENV === 'production' ? void 0 : '/usr/local/node/project/thtp-frontend/src/.umi-production/plugin-access/runtime.tsx',
    },
    {
      apply: Plugin_2,
      path: process.env.NODE_ENV === 'production' ? void 0 : '/usr/local/node/project/thtp-frontend/src/.umi-production/plugin-dva/runtime.tsx',
    },
    {
      apply: Plugin_3,
      path: process.env.NODE_ENV === 'production' ? void 0 : '/usr/local/node/project/thtp-frontend/src/.umi-production/plugin-initialState/runtime.tsx',
    },
    {
      apply: Plugin_4,
      path: process.env.NODE_ENV === 'production' ? void 0 : '/usr/local/node/project/thtp-frontend/src/.umi-production/plugin-model/runtime.tsx',
    },
    {
      apply: Plugin_5,
      path: process.env.NODE_ENV === 'production' ? void 0 : '/usr/local/node/project/thtp-frontend/src/.umi-production/plugin-locale/runtime.tsx',
    },
  ];
}

export function getValidKeys() {
  return ['patchRoutes','patchClientRoutes','modifyContextOpts','rootContainer','innerProvider','i18nProvider','accessProvider','dataflowProvider','outerProvider','render','onRouteChange','dva','getInitialState','request','locale','qiankun',];
}

let pluginManager = null;
export function createPluginManager() {
  pluginManager = PluginManager.create({
    plugins: getPlugins(),
    validKeys: getValidKeys(),
  });
  return pluginManager;
}

export function getPluginManager() {
  return pluginManager;
}
